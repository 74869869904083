export default{
    data(){
        return{

        }
    },
    methods:{
        confirmClose(){
          this.$emit('confirmClose')
        },
        cancelClose(){
          this.$emit('cancelClose')
        },
    }
}
